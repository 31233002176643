import {fromIdToIriReference, hydrate} from "@/utils/form";
import {URI_SUBSCRIPTION_PLAN} from "@api/doinsport/services/subscription-plans/subscription-plans.api";
import {refine} from "@/utils/classes";

export const SPORT_TYPE = 'sport';
export const LESSON_TYPE = 'lesson';
export const LEISURE_TYPE = 'leisure';
export const FORMULA_TYPE = 'formula';

export const EVERYONE_REGISTRATION = 'everyone';
export const SUBSCRIBERS_REGISTRATION = 'subscribers';
export const ALL_SUBSCRIBERS_REGISTRATION = 'all_subscribers';

export default class Price {
  id = null;
  name = null;
  label = null;
  description = null;
  minParticipantsCountLimit = null;
  maxParticipantsCountLimit = null;
  maxAccompanyingParticipantsCountLimit = null;
  minActivityLevel = null;
  maxActivityLevel = null;
  registrationAvailableOnline = true;
  registrationTimeBeforeStart = null;
  registrationAvailableFor = EVERYONE_REGISTRATION;
  allowedSubscriptionPlans = [];
  activity = null;
  category = null;
  activityType = null;
  form = null;
  participantsQueueEnabled = null;
  duration = null;
  mainPhoto = null;
  bookingComment = null;
  instalmentAmount = null;
  pricePerParticipant = null;
  maxBookingCountLimit = null;
  variations = [];
  paymentTokenPrices = [];
  participantCategories = [];
  playgroundOptions = [];
  club = JSON.parse(localStorage.getItem('selectedClub'));

  constructor(object, options) {
    if ("undefined" !== typeof options) {
      if (options.deserialize) {
        if ("undefined" !== typeof options.operation) {
          if (options.operation === 'row') {
            hydrate(this, object);
            this.castToString();
          }
        } else {
          this.deserialize(object);
        }
      }
      if (options.serialize) {
        this.serialize(object);
      }
    }
  }

  deserialize(object) {
    hydrate(this, object);
    this.setPricesToMotto();
    this.setDurationToMinute();
  }

  serialize(object) {
    hydrate(this, object);
    this.setPricesToCents();
    this.castToInt();
    this.setDurationToSecond();
    refine(this);
    this.formatBlockPrice(object);
  }

  setPricesToMotto() {
    this.pricePerParticipant = this.pricePerParticipant / 100;
    this.instalmentAmount = this.instalmentAmount ? this.instalmentAmount / 100 : null;
  }

  setDurationToSecond() {
    this.duration = parseInt(this.duration) * 60;
    this.registrationTimeBeforeStart = this.registrationTimeBeforeStart * 3600;
  }

  setDurationToMinute() {
    this.duration = parseInt(this.duration) / 60;
    this.registrationTimeBeforeStart = this.registrationTimeBeforeStart / 3600;
  }

  setPricesToCents() {
    this.pricePerParticipant = Math.round((parseFloat(this.pricePerParticipant, 2).toFixed(2)) * 100);
    this.instalmentAmount =  null !== this.instalmentAmount ? Math.round((parseFloat(this.instalmentAmount, 2).toFixed(2)) * 100) : null;
  }

  castToInt() {
    this.maxParticipantsCountLimit = parseInt(this.maxParticipantsCountLimit);
    if (this.activityType === FORMULA_TYPE) {
      this.minParticipantsCountLimit = parseInt(this.minParticipantsCountLimit);
    }
    this.duration = parseInt(this.duration);

    if (this.activityType === LESSON_TYPE) {
      this.maxAccompanyingParticipantsCountLimit = parseInt(this.maxAccompanyingParticipantsCountLimit);
      this.registrationTimeBeforeStart = parseFloat(this.registrationTimeBeforeStart, 2).toFixed(2);
    }
  }

  formatBlockPrice(object) {
    switch (object.activityType) {
      case LESSON_TYPE:
        delete this.mainPhoto;
        delete this.form;
        delete this.maxBookingCountLimit;
        switch (object.registrationAvailableFor) {
          case ALL_SUBSCRIBERS_REGISTRATION:
            delete this.allowedSubscriptionPlans;
            break;
          case EVERYONE_REGISTRATION:
            delete this.allowedSubscriptionPlans;
            break;
          case SUBSCRIBERS_REGISTRATION:
            const allowedSubscriptionPlans = [];

            if (object.allowedSubscriptionPlans.length > 0) {
              for (const item of object.allowedSubscriptionPlans) {
                allowedSubscriptionPlans.push(fromIdToIriReference(URI_SUBSCRIPTION_PLAN, item.code));
              }
            }
            this.allowedSubscriptionPlans = allowedSubscriptionPlans;
            break;
        }
        break;
      case SPORT_TYPE:
      case LEISURE_TYPE:
      case FORMULA_TYPE:
        delete this.maxAccompanyingParticipantsCountLimit;
        delete this.participantsQueueEnabled;
        delete this.registrationAvailableOnline;
        delete this.registrationTimeBeforeStart;
        delete this.registrationAvailableFor;
        delete this.allowedSubscriptionPlans;
        delete this.minActivityLevel;
        delete this.maxActivityLevel;
        delete this.mainPhoto;
        if (object.activityType !== FORMULA_TYPE) {
          delete this.form;
        }
        break;
      default:
        console.error('Unknown activity type', object.activityType);
    }
    if (object.id) {
      delete this.activityType;
    }
  }

  castToString() {
    this.maxParticipantsCountLimit = new String(this.maxParticipantsCountLimit).toString();
    this.minParticipantsCountLimit = new String(this.minParticipantsCountLimit).toString();
    this.duration = new String(this.duration).toString();
    this.registrationTimeBeforeStart = new String(this.registrationTimeBeforeStart).toString();
    this.pricePerParticipant = parseInt(new String(this.pricePerParticipant).toString());
    this.instalmentAmount = this.instalmentAmount ? parseInt(new String(this.instalmentAmount).toString()) : null;
  }
}
